const medicoAgendaPac = [
  {
    medico: 'Dr Alexandre',
    codigo: 958,
  },
  {
    medico: 'Drª Cassia',
    codigo: 957,
  },
  {
    medico: 'Dr Fabio',
    codigo: 5,
  },
  {
    medico: 'Drª Fernanda',
    codigo: 2030,
  },
  /*   {
    medico: 'Dr Igor',
    codigo: 3283,
  }, */
  {
    medico: 'Dr João',
    codigo: 2796,
  },
  {
    medico: 'Dr Luiz - Cardio Pediatra',
    codigo: 4860,
  },
  {
    medico: 'Drª Marina',
    codigo: 2657,
  },
  {
    medico: 'Dr Raphael',
    codigo: 3794,
  },
  {
    medico: 'Dr Roberto',
    codigo: 1628,
  },
  {
    medico: 'Dr Valdyr',
    codigo: 24,
  },
];

export default medicoAgendaPac;
