import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import md5 from 'md5';
import { useSelector } from 'react-redux';
import { Form, Button, Card, Modal, Collapse, Alert } from 'react-bootstrap';
import api from '../../../services/api';
import s3 from '../../../util/s3';
import header from '../../../assets/icm-header.jpg';
import blank from '../../../assets/icm-blank.jpg';
import styles from '../../../styles/styles.module.css';

export default function ReportDoppler() {
  const { eco } = useSelector(state => state.eco);
  const medico = useSelector(state => state.doctor.profile);
  const { printing } = useSelector(state => state.contexto);
  const { funcionario } = useSelector(state => state.funcionario);
  const [showModal, setShowModal] = useState(false);
  const [urls, setURLs] = useState([]);
  const [open, setOpen] = useState({});
  const [files, setFiles] = useState([]);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(null);
  const isAllowedToDeleteFiles =
    medico ||
    funcionario?.codFunc === '90035' ||
    funcionario?.codFunc === '90026' ||
    funcionario?.codFunc === '90001';
  let contador = 0;

  useEffect(() => {
    s3.listObjectsV2(
      { Bucket: 'prevencor', Prefix: `eco/${eco?.CODECO}` },
      (err, data) => {
        if (err) toast.error('Erro ao buscar arquivos. Entre em contato.');
        else
          setFiles(
            data.Contents.filter(
              item =>
                item.Key.includes('.webm') ||
                item.Key.includes('.pdf') ||
                item.Key.includes('.mp4')
            )
          );
      }
    );
  }, [eco?.CODECO]);

  function removeDiv(divName) {
    let newdiv = document.createElement('div');
    let originalContents = document.body.innerHTML;
    newdiv.innerHTML = originalContents;
    document.getElementById(divName).remove(newdiv);
    window.scrollTo(0, 0); // Scroll to the top of the page
    window.print();
    document.body.innerHTML = originalContents;
  }

  async function getFiles(f) {
    f = f.Key.split('/');

    return await api.get(
      `https://j87jesh814.execute-api.us-east-1.amazonaws.com/dev/image-upload/folder/eco/laudo/${eco?.CODECO}/key/${f[2]}`
    );
  }

  async function showFiles() {
    setShowModal(true);

    const array = [];

    for (let file of files) {
      const url = await getFiles(file);
      const obj = { url, ...file }; // coloco num obj só a presigned URL e o file, pq preciso da Key para deletar depois
      array.push(obj);
    }

    setURLs(array);
  }

  function handleClick(id) {
    setOpen(prevState => ({ ...prevState, [id]: !prevState[id] }));
  }

  function prefixo() {
    if (
      eco?.CODREALIZOU === 1 ||
      eco?.CODREALIZOU === 5 ||
      eco?.CODREALIZOU === 2796 ||
      eco?.CODREALIZOU === 958
    ) {
      return 'Prof. Dr. ';
    } else {
      return 'Dr. ';
    }
  }

  async function deleteS3Object(file) {
    try {
      await s3.deleteObject({ Bucket: 'prevencor', Key: file }).promise();
      toast.success('O arquivo foi deletado com sucesso!');
      setOpenDeleteAlert(null);
      setShowModal(false);
    } catch (error) {
      toast.error('Erro ao deletar arquivo. Entre em contato.');
    }
  }

  function handleDescricao() {
    return (
      <pre className={styles.preContent} style={{ fontSize: 20 }}>
        {eco.OBSERVACOES}
      </pre>
    );
  }

  return (
    <>
      <style>
        {`@media print {
          @page {
            margin-top:4cm;
            margin-bottom: 3cm;
          }

          .page {
            page-break-after: always;
          }

          .page:first-of-type {
            margin-top: 0;
          }

          .page:last-of-type {
            margin-bottom: 0;
            page-break-after: auto;
          }
        }`}
      </style>
      <Card>
        {printing ? (
          <div className={styles.imageReport}>
            <img width="550" height="100" src={header} alt="header" />
          </div>
        ) : (
          <div className={styles.imageReport}>
            {/* <img width="550" height="100" src={blank} alt="header" /> */}
          </div>
        )}
        <Card.Header className="text-center">
          <Card.Title className={styles.titleReport}>
            LAUDO DE ECOCARDIOGRAMA DOPPLER
          </Card.Title>
        </Card.Header>

        <Card.Body>
          {eco && eco?.TIPOLAUDO === 'Alterado' ? (
            <Form noValidate className={styles.contentReport}>
              <strong className="mr-auto">Nome do Paciente: </strong>
              {`${eco?.CODPAC} - ${eco?.NOMEPAC}`}
              <br></br>
              <strong className="mr-auto">Data de Nasc: </strong>
              {`${eco?.dataNascFmt}`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong className="mr-auto">Sexo: </strong>
              {`${eco?.Sexo}`}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong className="mr-auto">Idade: </strong>
              {`${eco?.idadePAC} anos`}
              &nbsp;&nbsp;&nbsp;
              <strong className="mr-auto">Tipo: </strong>
              {`${eco?.TIPOLAUDO}`}
              <br></br>
              <strong className="mr-auto">Convênio: </strong>
              {eco?.SIGLACONV}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong className="mr-auto">Cod Laudo: </strong>
              {eco?.CODECO}&nbsp;&nbsp;&nbsp;
              <strong className="mr-auto">Data do Exame: </strong>
              {eco?.dataEcoFmt}
              <br></br>
              <strong className="mr-auto">
                Médico que Realizou: {prefixo()}{' '}
              </strong>
              {`${eco?.Realizou}`}
              <br></br>
              <strong className="mr-auto">
                Médico que Solicitou: {prefixo()}{' '}
              </strong>
              {`${eco?.Solicitou}`}
              <hr></hr>
              {eco?.obs && eco?.obs?.length > 0 && (
                <>
                  <strong className="mr-auto">Observações: </strong>
                  {eco?.obs?.map(obs => (
                    <div className="row">
                      <div className="col-12"> {obs.DESCRICAO}</div>
                    </div>
                  ))}
                  <br></br>
                </>
              )}
              <br></br>
              <strong className="mr-auto">
                Diâmetros e Função Sistólica do Ventrículo Esquerdo{' '}
              </strong>
              <div className="row">
                <div className="col-6">{`Septo: ${eco?.SEPTO &&
                  eco?.SEPTO?.toFixed(1)} (cm)`}</div>
                {eco?.fracaoEjecaoMono !== null && (
                  <div className="col-6">{`Fração de Ejeção(Monoplanar): ${eco?.fracaoEjecaoMono}`}</div>
                )}
              </div>
              <div className="row">
                <div className="col-6">{`PPVE: ${eco?.PPVE &&
                  eco?.PPVE.toFixed(1)} (cm)`}</div>
                {eco?.FRAEJECBIPLA !== null && (
                  <div className="col-6">{`Fração de Ejeção(Simpson): ${eco?.FRAEJECBIPLA.toFixed(
                    2
                  )}`}</div>
                )}
              </div>
              <div className="row">
                <div className="col-6">{`Diâmetro Diastólico: ${eco?.DD &&
                  eco?.DD.toFixed(1)} (cm)`}</div>
                {eco?.FRAEJE3D !== null && (
                  <div className="col-6">{`Fração de Ejeção(3D): ${eco?.FRAEJE3D.toFixed(
                    2
                  )}`}</div>
                )}
              </div>
              <div className="row">
                {eco?.DS !== null && (
                  <div className="col-6">{`Diâmetro Sistólico: ${eco?.DS.toFixed(
                    1
                  )} (cm)`}</div>
                )}
                {eco?.massaVE !== null && (
                  <div className="col-6">{`Massa do VE: ${Math.ceil(
                    eco?.massaVE
                  )} (g/m2)`}</div>
                )}
              </div>
              <div className="row">
                <div className="col-6"></div>
                {eco?.avaliaVSVE !== null && (
                  <div className="col-6">{`Relação Espessura/Volume: ${eco?.avaliaVSVE &&
                    Number(eco?.avaliaVSVE).toFixed(2)}`}</div>
                )}
              </div>
              {eco?.achados?.length > 0 && (
                <>
                  <u className="mr-auto">Achados Descritivos do VE: </u>
                  {eco?.achados.map(achados => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {achados.DESCRICAO}</div>
                    </div>
                  ))}
                  <br></br>
                </>
              )}
              {eco?.contraRegiao?.length > 0 && (
                <>
                  <u className="mr-auto">Contração Segmentar: </u>
                  {eco?.contraRegiao?.map(contra => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {contra.CONTRASEG}</div>
                    </div>
                  ))}
                  <br></br>
                </>
              )}
              <strong className="mr-auto">
                Avaliação da Função Diastólica do Ventrículo Esquerdo{' '}
              </strong>
              {eco?.indicePM && !isNaN(eco?.indicePM) && (
                <div className="row">
                  <div className="col-12">{`Índice de Performance: ${eco?.indicePM.toFixed(
                    2
                  )} (Normal < 0.42)`}</div>
                </div>
              )}
              {eco?.estimativaDC && !isNaN(eco?.estimativaDC) ? (
                <div className="row">
                  <div className="col-12">{`Estimativa do Débito Cardíaco: ${eco?.estimativaDC} (l/min/m2) (2.4 a 4.2)`}</div>
                </div>
              ) : null}
              {eco?.avDiast?.length > 0 && (
                <>
                  {eco?.avDiast?.map(avDiast => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {avDiast.DESCRICAO}</div>
                    </div>
                  ))}
                  <br></br>
                </>
              )}
              <strong className="mr-auto">
                Átrio Esquerdo e Valva Mitral{' '}
              </strong>
              {eco?.DAE !== null && (
                <div className="row">
                  <div className="col-12">{`Diâmetro do Átrio Esquerdo: ${eco?.DAE.toFixed(
                    1
                  )} (cm)`}</div>
                </div>
              )}
              {eco?.VOL_AE !== null ? (
                <div className="row">
                  <div className="col-12">{`Volume do AE: ${eco?.VOL_AE.toFixed()} (cc/m2) (até 34)`}</div>
                </div>
              ) : null}
              {eco?.atrioEsq?.length > 0 && (
                <>
                  {eco?.atrioEsq?.map(atrioEsq => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {atrioEsq.DESCRICAO}</div>
                    </div>
                  ))}
                </>
              )}
              {eco?.valvaMitral?.length > 0 && (
                <>
                  {eco?.valvaMitral?.map(valvaMitral => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {valvaMitral.DESCRICAO}</div>
                    </div>
                  ))}
                  <br></br>
                </>
              )}
              {eco?.DAVAO !== null || eco?.valvaAO?.length > 0 ? (
                <div className="row">
                  <div className="col-12">
                    <strong className="mr-auto">Valva Aórtica </strong>
                  </div>
                </div>
              ) : null}
              {eco?.DAVAO !== null && (
                <div className="row">
                  <div className="col-12">{`Diâmetro da Raiz Aórtica: ${eco?.DAVAO.toFixed(
                    1
                  )} (cm)`}</div>
                </div>
              )}
              {eco?.valvaAO?.length > 0 ? (
                <>
                  {eco?.valvaAO?.map(valvaAO => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {valvaAO.DESCRICAO}</div>
                    </div>
                  ))}
                  <br></br>
                </>
              ) : null}
              {eco?.aorta?.length > 0 && (
                <div className="row">
                  <div className="col-12">
                    <strong className="mr-auto">Aorta </strong>
                  </div>
                </div>
              )}
              {eco?.aorta?.length > 0 ? (
                <>
                  {eco?.aorta?.map(aorta => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {aorta.DESCRICAO}</div>
                    </div>
                  ))}
                  <br></br>
                </>
              ) : null}
              {eco?.atrioDir?.length > 0 ? (
                <div className="row">
                  <div className="col-12">
                    <strong className="mr-auto">Átrio Direito </strong>
                  </div>
                </div>
              ) : null}
              {eco?.atrioDir?.length > 0 ? (
                <>
                  {eco?.atrioDir?.map(atrioDir => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {atrioDir.DESCRICAO}</div>
                    </div>
                  ))}
                  <br></br>
                </>
              ) : null}
              {eco?.veiaCavaInf?.length > 0 ? (
                <div className="row">
                  <div className="col-12">
                    <strong className="mr-auto">Veia Cava Inferior </strong>
                  </div>
                </div>
              ) : null}
              {eco?.veiaCavaInf?.length > 0 ? (
                <>
                  {eco?.veiaCavaInf?.map(veiaCavaInf => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {veiaCavaInf.DESCRICAO}</div>
                    </div>
                  ))}
                  <br></br>
                </>
              ) : null}
              {eco?.ventriDir?.length > 0 ? (
                <div className="row">
                  <div className="col-12">
                    <strong className="mr-auto">Ventrículo Direito </strong>
                  </div>
                </div>
              ) : null}
              {eco?.ventriDir?.length > 0 ? (
                <>
                  {eco?.ventriDir?.map(ventriDir => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {ventriDir.DESCRICAO}</div>
                    </div>
                  ))}
                  <br></br>
                </>
              ) : null}
              {eco?.valvaTricu?.length > 0 ? (
                <div className="row">
                  <div className="col-12">
                    <strong className="mr-auto">Valva Tricúspide</strong>
                  </div>
                </div>
              ) : null}
              {eco?.valvaTricu?.length > 0 ? (
                <>
                  {eco?.valvaTricu?.map(valvaTricu => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {valvaTricu.DESCRICAO}</div>
                    </div>
                  ))}
                  <br></br>
                </>
              ) : null}
              {eco?.DAVP !== null || eco?.valvaPulmo?.length > 0 ? (
                <div className="row">
                  <div className="col-12">
                    <strong className="mr-auto">Valva Pulmonar</strong>
                  </div>
                </div>
              ) : null}
              {eco?.DAVP !== null ? (
                <div className="row">
                  <div className="col-12">{`Diâmetro da Anel Pulmonar: ${eco?.DAVP.toFixed(
                    1
                  )} (cm)`}</div>
                </div>
              ) : null}
              {eco?.valvaPulmo?.length > 0 ? (
                <>
                  {eco?.valvaPulmo?.map(valvaPulmo => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {valvaPulmo.DESCRICAO}</div>
                    </div>
                  ))}
                  <br></br>
                </>
              ) : null}
              {eco?.pericardio?.length > 0 ? (
                <>
                  <div className="row">
                    <div className="col-12">
                      <strong className="mr-auto">Pericárdio</strong>
                    </div>
                  </div>
                  {eco?.pericardio?.map(pericardio => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {pericardio.DESCRICAO}</div>
                    </div>
                  ))}
                  <br></br>
                </>
              ) : null}
              {eco?.AVM !== null ||
              eco?.ORIFICIO_VM !== null ||
              eco?.GMVM !== null ||
              eco?.VOLUME_VM !== null ||
              eco?.refVM?.length > 0 ? (
                <strong className="mr-auto">Doppler Mitral</strong>
              ) : null}
              <div className="row">
                {eco?.AVM !== null ? (
                  <div className="col-12">{`Área Valva Mitral: ${eco?.AVM.toFixed(
                    1
                  )} (cm2) ${eco?.valorAVM}`}</div>
                ) : null}
                {eco?.ORIFICIO_VM !== null ? (
                  <div className="col-12">{`Orifício Regurgitação Mitral (PISA): ${eco?.ORIFICIO_VM.toFixed(
                    2
                  )} (cm2)`}</div>
                ) : null}
              </div>
              <div className="row">
                {eco?.GMVM !== null ? (
                  <div className="col-12">{`Gradiente Médio: ${eco?.GMVM} (mmHg)`}</div>
                ) : null}
                {eco?.VOLUME_VM !== null ? (
                  <div className="col-12">{`Volume Regurgitação Mitral: ${eco?.VOLUME_VM} (cc)`}</div>
                ) : null}
              </div>
              {eco?.refVM?.length > 0 ? (
                <>
                  {eco?.refVM?.map(refVM => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {refVM.DESCRICAO}</div>
                    </div>
                  ))}
                  <br></br>
                </>
              ) : null}
              {eco?.AREA_AO !== null ||
              eco?.GMVAO !== null ||
              eco?.GMVSVE !== null ||
              eco?.refVAO.length > 0 ? (
                <strong className="mr-auto">Doppler Aórtico</strong>
              ) : null}
              {eco?.AREA_AO !== null ? (
                <div className="row">
                  <div className="col-12">{`Área Valva Aórtica: ${eco?.AREA_AO.toFixed(
                    2
                  )} (cm2) ${eco?.valorAreaAVAO}`}</div>
                </div>
              ) : (
                ''
              )}
              {eco?.GMVAO !== null ? (
                <div className="row">
                  <div className="col-12">{`Gradiente Médio da Valva Aórtica: ${eco?.GMVAO} (mmHg)`}</div>
                </div>
              ) : (
                ''
              )}
              {eco?.GMVSVE !== null ? (
                <div className="row">
                  <div className="col-12">{`Gradiente Médio Via de Saída do VE: ${eco?.GMVSVE} (mmHg)`}</div>
                </div>
              ) : (
                ''
              )}
              {eco?.refVAO?.length > 0 ? (
                <>
                  {eco?.refVAO?.map(refVAO => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {refVAO.DESCRICAO}</div>
                    </div>
                  ))}
                  <br></br>
                </>
              ) : (
                <br></br>
              )}
              {eco?.GMVT !== null ||
              eco?.estimativaPSVD !== null ||
              eco?.refVT?.length > 0 ? (
                <strong className="mr-auto">Doppler Tricúspide</strong>
              ) : null}
              {eco?.GMVT !== null ? (
                <div className="row">
                  <div className="col-12">{`Gradiente Médio da Valva Tricúspide: ${eco?.GMVT} (mmHg)`}</div>
                </div>
              ) : null}
              {eco?.estimativaPSVD !== null ? (
                <div className="row">
                  <div className="col-12">{`Estimativa da Pressão Sistólica da AP: ${eco?.estimativaPSVD} (mmHg)`}</div>
                </div>
              ) : null}
              {eco?.refVT?.length > 0 ? (
                <>
                  {eco?.refVT?.map(refVT => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {refVT.DESCRICAO}</div>
                    </div>
                  ))}
                  <br></br>
                </>
              ) : null}
              {eco?.GMVP !== null || eco?.refVP?.length > 0 ? (
                <strong className="mr-auto">Doppler Pulmonar</strong>
              ) : null}
              {eco?.GMVP !== null ? (
                <div className="row">
                  <div className="col-12">{`Gradiente Médio da Valva Pulmonar: ${eco?.GMVP} (mmHg)`}</div>
                </div>
              ) : (
                ''
              )}
              {eco?.refVP?.length > 0 ? (
                <>
                  {eco?.refVP?.map(refVP => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {refVP.DESCRICAO}</div>
                    </div>
                  ))}
                  <br></br>
                </>
              ) : null}
              {eco?.GMPM !== null || eco?.refluxoPM.length > 0 ? (
                <strong className="mr-auto">Prótese Mitral</strong>
              ) : null}
              {eco?.GMPM !== null ? (
                <div className="row">
                  <div className="col-12">{`Gradiente Médio da Prótese Mitral: ${eco?.GMPM} (mmHg)`}</div>
                </div>
              ) : null}
              {eco?.refluxoPM?.length > 0 ? (
                <>
                  {eco?.refluxoPM?.map(refluxoPM => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {refluxoPM.refluxoPM}</div>
                    </div>
                  ))}
                  <br></br>
                </>
              ) : null}
              {eco?.GMPAO !== null || eco?.refluxoPAO.length > 0 ? (
                <strong className="mr-auto">Prótese Aórtica</strong>
              ) : null}
              {eco?.GMPAO !== null ? (
                <div className="row">
                  <div className="col-12">{`Gradiente Médio da Prótese Aórtica: ${eco?.GMPAO} (mmHg)`}</div>
                </div>
              ) : null}
              {eco?.refluxoPAO?.length > 0 ? (
                <>
                  {eco?.refluxoPAO?.map(refluxoPAO => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {refluxoPAO.refluxoPAO}</div>
                    </div>
                  ))}
                  <br></br>
                </>
              ) : null}
              {eco?.GMPT !== null || eco?.refluxoPT.length > 0 ? (
                <strong className="mr-auto">Prótese Tricúspide</strong>
              ) : null}
              {eco?.GMPT !== null ? (
                <>
                  <div className="row">
                    <div className="col-12">{`Gradiente Médio da Prótese Tricúspide: ${eco?.GMPT} (mmHg)`}</div>
                  </div>
                </>
              ) : null}
              {eco?.refluxoPT.length > 0 ? (
                <>
                  {eco?.refluxoPT?.map(refluxoPT => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {refluxoPT.refluxoPT}</div>
                    </div>
                  ))}
                  <br></br>
                </>
              ) : null}
              {eco?.GMPP !== null || eco?.refluxoPP.length > 0 ? (
                <strong className="mr-auto">Prótese Pulmonar</strong>
              ) : null}
              {eco?.GMPP !== null ? (
                <div className="row">
                  <div className="col-12">{`Gradiente Médio da Prótese Pulmonar: ${eco?.GMPP} (mmHg)`}</div>
                </div>
              ) : null}
              {eco?.refluxoPP?.length > 0 ? (
                <>
                  {eco?.refluxoPP?.map(refluxoPP => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {refluxoPP.refluxoPP}</div>
                    </div>
                  ))}
                  <br></br>
                </>
              ) : null}
              {eco?.OBSERVACOES !== null ? (
                <>
                  <strong className="mr-auto">Observações</strong>
                  <div className="row">
                    <div id="ecoObs" className="col-12">
                      {handleDescricao()}
                    </div>
                  </div>
                  <br></br>
                </>
              ) : null}
              <strong className="mr-auto">Conclusões</strong>
              {eco?.valvulopatia?.length > 0 ? (
                <>
                  {eco?.valvulopatia?.map(valvu => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {valvu.valvulopatia}</div>
                    </div>
                  ))}
                </>
              ) : null}
              {eco?.protese?.length > 0 ? (
                <>
                  {eco?.protese?.map(prot => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {prot.protese}</div>
                    </div>
                  ))}
                </>
              ) : null}
              {eco?.contraSeg?.length > 0 ? (
                <>
                  {eco?.contraSeg?.map(contra => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {contra.contraSeg}</div>
                    </div>
                  ))}
                </>
              ) : null}
              {eco?.conclusoes?.length > 0 ? (
                <>
                  {eco?.conclusoes?.map(conclu => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {conclu.conclusoes}</div>
                    </div>
                  ))}
                </>
              ) : null}
              <Card className="mt-3 text-center">
                <Card.Body>
                  {files.length > 0 && (
                    <div className={styles.messageReport}>
                      Imagens disponíveis no site:{' '}
                      <strong>www.icm.com.br (Resultado de Exames)</strong>
                      {/* TEMPORÁRIO */}
                      <br />
                      <span>Código de verificação: {eco?.CODPAC}</span>
                      <hr></hr>
                    </div>
                  )}
                  <div className={styles.signatureReport}>
                    <i className="mr-auto">
                      Conferido e assinado eletronicamente por:{' '}
                    </i>
                    <strong className="mr-auto">
                      {' '}
                      {prefixo()} {eco?.Realizou}
                    </strong>
                    <br></br>
                    <i className="mr-auto">
                      Assinatura Digital: {md5(JSON.stringify(eco))}
                    </i>
                  </div>
                </Card.Body>
                <Card.Footer id="removeMe" className="text-muted">
                  <Button onClick={() => removeDiv('removeMe')}>
                    Imprimir
                  </Button>{' '}
                  <Button variant="success" onClick={() => showFiles()}>
                    Ver Imagem
                  </Button>
                </Card.Footer>
              </Card>
            </Form>
          ) : (
            <Form noValidate className={styles.contentReport}>
              <strong className="mr-auto">Nome do Paciente: </strong>
              {`${eco?.CODPAC} - ${eco?.NOMEPAC}`}
              <br></br>
              <strong className="mr-auto">Data de Nasc: </strong>
              {`${eco?.dataNascFmt}`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong className="mr-auto">Sexo: </strong>
              {`${eco?.Sexo}`}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong className="mr-auto">Idade: </strong>
              {`${eco?.idadePAC} anos`}&nbsp;&nbsp;&nbsp;
              <strong className="mr-auto">Tipo: </strong>
              {`${eco?.TIPOLAUDO}`}
              <br></br>
              <strong className="mr-auto">Convênio: </strong>
              {eco?.SIGLACONV}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <strong className="mr-auto">Cod Laudo: </strong>
              {eco?.CODECO}&nbsp;&nbsp;&nbsp;
              <strong className="mr-auto">Data do Exame: </strong>
              {eco?.dataEcoFmt}
              <br></br>
              <strong className="mr-auto">
                Médico que Realizou: {prefixo()}{' '}
              </strong>
              {`${eco?.Realizou}`}
              <br></br>
              <strong className="mr-auto">
                Médico que Solicitou: {prefixo()}{' '}
              </strong>
              {`${eco?.Solicitou}`}
              <hr></hr>
              {eco?.obs && eco?.obs.length > 0 ? (
                <>
                  <strong className="mr-auto">Observações: </strong>
                  {eco?.obs.map(obs => (
                    <div className="row">
                      <div className="col-12"> {obs.DESCRICAO}</div>
                    </div>
                  ))}
                  <br></br>
                </>
              ) : null}
              <br></br>
              <strong className="mr-auto">
                Diâmetros e Função Sistólica do Ventrículo Esquerdo{' '}
              </strong>
              <div className="row">
                <div className="col-6">{`Septo: ${eco?.SEPTO &&
                  eco?.SEPTO.toFixed(1)} (cm)`}</div>
                {eco?.fracaoEjecaoMono !== null ? (
                  <div className="col-6">{`Fração de Ejeção(Monoplanar): ${eco?.fracaoEjecaoMono}`}</div>
                ) : null}
              </div>
              <div className="row">
                <div className="col-6">{`PPVE: ${eco?.PPVE &&
                  eco?.PPVE.toFixed(1)} (cm)`}</div>
                {eco?.FRAEJECBIPLA !== null ? (
                  <div className="col-6">{`Fração de Ejeção(Simpson): ${eco?.FRAEJECBIPLA &&
                    eco?.FRAEJECBIPLA.toFixed(2)}`}</div>
                ) : null}
              </div>
              <div className="row">
                <div className="col-6">{`Diâmetro Diastólico: ${eco?.DD &&
                  eco?.DD.toFixed(1)} (cm)`}</div>
                {eco?.FRAEJE3D !== null ? (
                  <div className="col-6">{`Fração de Ejeção(3D): ${eco?.FRAEJE3D &&
                    eco?.FRAEJE3D.toFixed(2)}`}</div>
                ) : null}
              </div>
              <div className="row">
                <div className="col-6">{`Diâmetro Sistólico: ${eco?.DS &&
                  eco?.DS.toFixed(1)} (cm)`}</div>
                {eco?.massaVE !== null ? (
                  <div className="col-6">{`Massa do VE: ${eco?.massaVE} (g/m2)`}</div>
                ) : null}
              </div>
              <div className="row">
                <div className="col-6"></div>
                {eco?.avaliaVSVE !== null ? (
                  <div className="col-6">{`Relação Espessura/Volume: ${eco?.avaliaVSVE &&
                    Number(eco?.avaliaVSVE).toFixed(2)}`}</div>
                ) : null}
              </div>
              {eco?.achados?.length > 0 ? (
                <>
                  <u className="mr-auto">Achados Descritivos do VE: </u>
                  {eco?.achados.map(achados => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {achados.DESCRICAO}</div>
                    </div>
                  ))}
                  <br></br>
                </>
              ) : null}
              {eco?.contraRegiao && eco?.contraRegiao.length > 0 ? (
                <>
                  <u className="mr-auto">Contração Segmentar: </u>
                  {eco?.contraRegiao.map(contra => (
                    <div key={contador++} className="row">
                      <div className="col-12">{contra.CONTRASEG}</div>
                    </div>
                  ))}
                  <br></br>
                </>
              ) : null}
              <strong className="mr-auto">
                Átrio Esquerdo e Valva Mitral{' '}
              </strong>
              {eco?.DAE !== null ? (
                <div className="row">
                  <div className="col-6">{`Diâmetro do Átrio Esquerdo: ${eco?.DAE &&
                    eco?.DAE.toFixed(1)} (cm)`}</div>
                </div>
              ) : null}
              {eco?.VOL_AE && eco?.VOL_AE !== null ? (
                <div className="row">
                  <div className="col-6">{`Volume do AE: ${eco?.VOL_AE} (cc/m2) (até 34)`}</div>
                </div>
              ) : (
                ''
              )}
              {eco?.atrioEsq && eco?.atrioEsq.length > 0 ? (
                <>
                  {eco?.atrioEsq?.map(atrioEsq => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {atrioEsq.DESCRICAO}</div>
                    </div>
                  ))}
                </>
              ) : null}
              {eco?.valvaMitral && eco?.valvaMitral.length > 0 ? (
                <>
                  {eco?.valvaMitral?.map(valvaMitral => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {valvaMitral.DESCRICAO}</div>
                    </div>
                  ))}
                  <br></br>
                </>
              ) : null}
              {eco?.DAVAO !== null || eco?.valvaAO.length > 0 ? (
                <div className="row">
                  <div className="col-12">
                    <strong className="mr-auto">Valva Aórtica </strong>
                  </div>
                </div>
              ) : null}
              {eco?.DAVAO !== null ? (
                <div className="row">
                  <div className="col-12">{`Diâmetro da Raiz Aórtica: ${eco?.DAVAO &&
                    eco?.DAVAO.toFixed(1)} (cm)`}</div>
                </div>
              ) : null}
              {eco?.valvaAO && eco?.valvaAO.length > 0 ? (
                <>
                  {eco?.valvaAO.map(valvaAO => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {valvaAO.DESCRICAO}</div>
                    </div>
                  ))}
                  <br></br>
                </>
              ) : null}
              {eco?.aorta.length > 0 ? (
                <div className="row">
                  <div className="col-12">
                    <strong className="mr-auto">Aorta </strong>
                  </div>
                </div>
              ) : null}
              {eco?.aorta && eco?.aorta.length > 0 ? (
                <>
                  {eco?.aorta.map(aorta => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {aorta.DESCRICAO}</div>
                    </div>
                  ))}
                  <br></br>
                </>
              ) : null}
              {eco?.atrioDir?.length > 0 ? (
                <div className="row">
                  <div className="col-12">
                    <strong className="mr-auto">Átrio Direito </strong>
                  </div>
                </div>
              ) : null}
              {eco?.atrioDir && eco?.atrioDir.length > 0 ? (
                <>
                  {eco?.atrioDir?.map(atrioDir => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {atrioDir.DESCRICAO}</div>
                    </div>
                  ))}
                  <br></br>
                </>
              ) : null}
              {eco?.ventriDir?.length > 0 ? (
                <div className="row">
                  <div className="col-12">
                    <strong className="mr-auto">Ventrículo Direito </strong>
                  </div>
                </div>
              ) : null}
              {eco?.ventriDir && eco?.ventriDir?.length > 0 ? (
                <>
                  {eco?.ventriDir?.map(ventriDir => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {ventriDir.DESCRICAO}</div>
                    </div>
                  ))}
                  <br></br>
                </>
              ) : null}
              {eco?.valvaTricu.length > 0 ? (
                <div className="row">
                  <div className="col-12">
                    <strong className="mr-auto">Valva Tricúspide</strong>
                  </div>
                </div>
              ) : null}
              {eco?.valvaTricu && eco?.valvaTricu.length > 0 ? (
                <>
                  {eco?.valvaTricu?.map(valvaTricu => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {valvaTricu.DESCRICAO}</div>
                    </div>
                  ))}
                  <br></br>
                </>
              ) : null}
              {eco?.valvaPulmo?.length > 0 ? (
                <div className="row">
                  <div className="col-12">
                    <strong className="mr-auto">Valva Pulmonar</strong>
                  </div>
                </div>
              ) : null}
              {eco?.valvaPulmo && eco?.valvaPulmo.length > 0 ? (
                <>
                  {eco?.valvaPulmo?.map(valvaPulmo => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {valvaPulmo.DESCRICAO}</div>
                    </div>
                  ))}
                  <br></br>
                </>
              ) : null}
              {eco?.pericardio && eco?.pericardio.length > 0 ? (
                <>
                  <div className="row">
                    <div className="col-12">
                      <strong className="mr-auto">Pericárdio</strong>
                    </div>
                  </div>
                  {eco?.pericardio?.map(pericardio => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {pericardio.DESCRICAO}</div>
                    </div>
                  ))}
                  <br></br>
                </>
              ) : null}
              <strong className="mr-auto">
                Avaliação da Função Diastólica do Ventrículo Esquerdo{' '}
              </strong>
              {eco?.avDiast && eco?.avDiast.length > 0 ? (
                <>
                  {eco?.avDiast.map(avDiast => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {avDiast.DESCRICAO}</div>
                    </div>
                  ))}
                  <br></br>
                </>
              ) : null}
              <strong className="mr-auto">Doppler Mitral</strong>
              {eco?.refVM && eco?.refVM.length > 0 ? (
                <>
                  {eco?.refVM.map(refVM => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {refVM.DESCRICAO}</div>
                    </div>
                  ))}
                  <br></br>
                </>
              ) : null}
              <strong className="mr-auto">Doppler Aórtico</strong>
              {eco?.refVAO && eco?.refVAO.length > 0 ? (
                <>
                  {eco?.refVAO.map(refVAO => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {refVAO.DESCRICAO}</div>
                    </div>
                  ))}
                  <br></br>
                </>
              ) : (
                <br></br>
              )}
              <strong className="mr-auto">Doppler Tricúspide</strong>
              {eco?.refVT && eco?.refVT.length > 0 ? (
                <>
                  {eco?.refVT?.map(refVT => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {refVT.DESCRICAO}</div>
                    </div>
                  ))}
                  <br></br>
                </>
              ) : null}
              <strong className="mr-auto">Doppler Pulmonar</strong>
              {eco?.refVP && eco?.refVP.length > 0 ? (
                <>
                  {eco?.refVP.map(refVP => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {refVP.DESCRICAO}</div>
                    </div>
                  ))}
                  <br></br>
                </>
              ) : null}
              {eco?.OBSERVACOES?.trim() !== '' ? (
                <>
                  <strong className="mr-auto">Observações</strong>
                  <div className="row">
                    <div id="ecoObs" className="col-12">
                      {handleDescricao()}
                    </div>
                  </div>

                  <br></br>
                </>
              ) : (
                ''
              )}
              <strong className="mr-auto">Conclusões</strong>
              {eco?.conclusoes && eco?.conclusoes.length > 0 ? (
                <>
                  {eco?.conclusoes.map(conclu => (
                    <div key={contador++} className="row">
                      <div className="col-12"> {conclu.conclusoes}</div>
                    </div>
                  ))}
                </>
              ) : null}
              <Card className="mt-3 text-center">
                <Card.Body>
                  {files.length > 0 && (
                    <div className={styles.messageReport}>
                      Imagens disponíveis no site:{' '}
                      <strong>www.icm.com.br (Resultado de Exames)</strong>
                      {/* TEMPORÁRIO */}
                      <br />
                      <span>Código de verificação: {eco?.CODPAC}</span>
                      <hr />
                    </div>
                  )}
                  <div className={styles.signatureReport}>
                    <i className="mr-auto">
                      Conferido e assinado eletronicamente por:{' '}
                    </i>
                    <strong className="mr-auto">
                      {' '}
                      {prefixo()} {eco?.Realizou}
                    </strong>
                    <br></br>
                    <i className="mr-auto">
                      Assinatura Digital: {md5(JSON.stringify(eco))}
                    </i>
                  </div>
                </Card.Body>
                <Card.Footer id="removeMe" className="text-muted">
                  <Button onClick={() => removeDiv('removeMe')}>
                    Imprimir
                  </Button>{' '}
                  <Button variant="success" onClick={() => showFiles()}>
                    Ver Imagem
                  </Button>
                </Card.Footer>
              </Card>
            </Form>
          )}
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Visualização</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {urls.map((url, idx) => {
            if (isAllowedToDeleteFiles) {
              return (
                <div>
                  <Card>
                    <Alert
                      show={openDeleteAlert === url.Key}
                      variant="danger"
                      onClose={() => setOpenDeleteAlert(null)}
                      dismissible
                    >
                      <Alert.Heading>
                        Você tem certeza que quer deletar a imagem?
                      </Alert.Heading>
                      <hr />
                      <div className="d-flex justify-content-">
                        <Button
                          onClick={() => deleteS3Object(url.Key)}
                          variant="danger"
                        >
                          Confirmar
                        </Button>
                      </div>
                    </Alert>
                    <div class="btn-group">
                      <button
                        class="btn btn-primary"
                        onClick={() => handleClick(url.url.data.preSignedUrl)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                        style={{ color: 'white' }}
                      >
                        Imagem {idx + 1}
                      </button>
                      <button
                        class="btn btn-danger"
                        onClick={() => setOpenDeleteAlert(url.Key)}
                        style={{ color: 'white' }}
                      >
                        Deletar
                      </button>
                    </div>
                  </Card>
                  <Collapse in={open[url.url.data.preSignedUrl]}>
                    <div
                      key={url.url.data.preSignedUrl}
                      id="collapse-div"
                      className="collapsedText"
                    >
                      {url.url.data.preSignedUrl.includes('.webm') ||
                      url.url.data.preSignedUrl.includes('.mp4') ? (
                        <div style={{ textAlign: 'center', display: 'block' }}>
                          <video
                            id="video-laudo"
                            width="500"
                            height="500"
                            controls
                          >
                            <source
                              src={url.url.data.preSignedUrl}
                              type={
                                url.url.data.preSignedUrl.endsWith('.mp4')
                                  ? 'video/mp4'
                                  : 'video/webm'
                              }
                            />
                          </video>
                        </div>
                      ) : (
                        <div style={{ textAlign: 'center', display: 'block' }}>
                          <embed
                            src={url.url.data.preSignedUrl}
                            width="600px"
                            height="900px"
                          />
                        </div>
                      )}
                    </div>
                  </Collapse>
                </div>
              );
            } else {
              return (
                <div>
                  <Card>
                    <div class="btn-group">
                      <button
                        class="btn btn-primary"
                        onClick={() => handleClick(url.url.data.preSignedUrl)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                        style={{ color: 'white' }}
                      >
                        Imagem {idx + 1}
                      </button>
                    </div>
                  </Card>
                  <Collapse in={open[url.url.data.preSignedUrl]}>
                    <div
                      key={url.url.data.preSignedUrl}
                      id="collapse-div"
                      className="collapsedText"
                    >
                      {url.url.data.preSignedUrl.includes('.webm') ||
                      url.url.data.preSignedUrl.includes('.mp4') ? (
                        <div style={{ textAlign: 'center', display: 'block' }}>
                          <video
                            id="video-laudo"
                            width="500"
                            height="500"
                            controls
                          >
                            <source
                              src={url.url.data.preSignedUrl}
                              type={
                                url.url.data.preSignedUrl.endsWith('.mp4')
                                  ? 'video/mp4'
                                  : 'video/webm'
                              }
                            />
                          </video>
                        </div>
                      ) : (
                        <div style={{ textAlign: 'center', display: 'block' }}>
                          <embed
                            src={url.url.data.preSignedUrl}
                            width="600px"
                            height="900px"
                          />
                        </div>
                      )}
                    </div>
                  </Collapse>
                </div>
              );
            }
          })}
        </Modal.Body>
      </Modal>
    </>
  );
}
