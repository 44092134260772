import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Card, Image, Spinner, Alert } from 'react-bootstrap';
import api from '../../../services/api';

// TODO: ASK CLAUDE about this solution w/ token
export default function PublicEcg() {
  const { token } = useParams();
  const [ecgData, setEcgData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEcgData = async () => {
      try {
        const response = await api.get(`/report/${token}`);
        setEcgData(response.data.ecg[0]);
      } catch (error) {
        setError(error.response.data.error);
      }
    };

    fetchEcgData();
  }, [token]);

  if (error) {
    return (
      <Container className="mt-5">
        <Alert variant="danger">{error}</Alert>
      </Container>
    );
  }

  if (!ecgData) {
    return (
      <Container className="mt-5 text-center">
        <Spinner animation="border" role="status" />
      </Container>
    );
  }

  return (
    <Container className="mt-2">
      <Card>
        <Card.Header className="text-center">
          <Card.Title>LAUDO DE ELETROCARDIOGRAMA - {ecgData.CODECG}</Card.Title>
        </Card.Header>
        <Card.Body>
          <strong className="mr-auto">Nome do Paciente: </strong>
          {`${ecgData.CODPAC} - ${ecgData.NOMEPAC}`}
          <br></br>
          <strong className="mr-auto">Data de Nasc: </strong>
          {`${ecgData.dataNasc}`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Sexo: </strong>
          {`${ecgData.SEXOPAC}`}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Idade: </strong>
          {`${ecgData.idadePAC} anos`}
          <br></br>
          <strong className="mr-auto">Convênio: </strong>
          {ecgData.SIGLACONV}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Cod ECG: </strong>
          {ecgData.CODECG}&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Data do Exame: </strong>
          {ecgData.dataEcg}
          <br></br>
          <strong className="mr-auto">Médico que Realizou: Dr. </strong>
          {`${ecgData.Realizou}`}
          <br></br>
          <strong className="mr-auto">Médico que Solicitou: Dr. </strong>
          {`${ecgData.Solicitou}`}
          <br></br>
          <Image src={ecgData.ARQUIVO_URL} alt="ECG Imagem" fluid />
          <br></br>
          <strong>Descrição</strong>
          <br></br>
          {ecgData.OBSERVACAO}
        </Card.Body>
      </Card>
    </Container>
  );
}
