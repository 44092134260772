import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { toast } from 'react-toastify';
import { Button, Card, Form, Modal, Table } from 'react-bootstrap';
import { addDays, parseISO, format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import styles from '../../../styles/styles.module.css';
import medicoAgenda from '../../../util/medicoAgenda';
import api from '../../../services/api';
import { faturamentoSuccess } from '../../../redux/features/context/faturamentoSlice';

const FaturamentoMedTable = ({ medico, data, totalPorMedico }) => {
  return (
    <div>
      <h5>Médico: {medico.split('-')[1]}</h5>
      <Table bordered hover size="sm">
        <thead>
          <tr>
            <th style={{ width: '30%' }}>Paciente</th>
            <th style={{ width: '30%' }}>Procedimento</th>
            <th style={{ width: '15%' }}>Valor</th>
            <th style={{ width: '15%' }}>Forma de pagamento</th>
            <th style={{ width: '10%' }}>Opções</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(data).map(date => {
            return (
              <React.Fragment key={date}>
                {data[date].map(item => (
                  <Row key={item.CODFATURA} item={item} />
                ))}
                <tr>
                  <td colSpan="3" align="right">
                    <strong>Total:&nbsp;</strong>
                  </td>
                  <td>
                    {totalPorMedico[medico][date].toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

const Row = ({ item }) => (
  <tr>
    <td>{item?.NOMEPAC}</td>
    <td>{item?.DESCPROCEDI}</td>
    <td>
      {item?.VLRLAUDO?.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })}
    </td>
    <td>{item.ARQUIVO_URI ?? ''}</td>
    <td>
      <Button
        size="sm"
        onClick={() =>
          window.openEditFatModal(
            item.NOMEPAC,
            item.DESCPROCEDI,
            item.VLRLAUDO,
            item.CODFATURA,
            item.ARQUIVO_URI ?? ''
          )
        }
      >
        Editar
      </Button>
    </td>
  </tr>
);

export default function FormFechamento() {
  const dispatch = useDispatch();
  const { fechamento } = useSelector(state => state.faturamento);
  const [observacoes, setObservacoes] = useState('');
  const medicosSorted = useMemo(
    () =>
      medicoAgenda
        .slice(0, 12)
        .sort((a, b) => a.medico.localeCompare(b.medico)),
    []
  );
  const dataFormatada = useMemo(
    () =>
      fechamento[0]?.DATALAUDO &&
      format(addDays(parseISO(fechamento[0].DATALAUDO), 1), 'dd/MM/yyyy', {
        locale: pt,
      }),
    [fechamento]
  );
  const [selectedMedico, setSelectedMedico] = useState([]);
  const [nomePaciente, setNomePaciente] = useState('');
  const [formaPagamento, setFormaPagamento] = useState('');
  const [procedimento, setProcedimento] = useState('');
  const [idFat, setIdFat] = useState();
  const [valorFat, setValorFat] = useState();
  const [editFatModal, setEditFatModal] = useState(false);

  const filterFechamento = useMemo(() => {
    return fechamento.filter(
      f => selectedMedico.length === 0 || selectedMedico.includes(f.CODMEDICO)
    );
  }, [fechamento, selectedMedico]);

  function agruparDadosPorMedicoEData(dados) {
    const relatorio = {};
    dados.forEach(item => {
      const chaveMedico = `${item.CODMEDICO}-${item.NOMEMED}`;
      const data = new Date(item.DATALAUDO).toLocaleDateString('pt-BR');

      if (!relatorio[chaveMedico]) relatorio[chaveMedico] = {};
      if (!relatorio[chaveMedico][data]) relatorio[chaveMedico][data] = [];
      relatorio[chaveMedico][data].push(item);
    });
    return relatorio;
  }

  function calcularTotalPorMedico(relatorio) {
    const totalPorMedico = {};
    for (const medico in relatorio) {
      totalPorMedico[medico] = {};
      for (const data in relatorio[medico]) {
        totalPorMedico[medico][data] = relatorio[medico][data].reduce(
          (total, item) => total + item.VLRLAUDO,
          0
        );
      }
    }
    return totalPorMedico;
  }

  window.openEditFatModal = function(nomePac, procedi, valor, codFat, pag) {
    setNomePaciente(nomePac);
    setProcedimento(procedi);
    setValorFat(valor);
    setIdFat(codFat);
    setFormaPagamento(pag); // ARQUIVO_URI no banco
    setEditFatModal(true);
  };

  const handleSelectMedico = agenda => {
    setSelectedMedico(prevSelectedMedico => {
      if (prevSelectedMedico.includes(agenda.codigo)) {
        return prevSelectedMedico.filter(codigo => codigo !== agenda.codigo);
      } else {
        return [...prevSelectedMedico, agenda.codigo];
      }
    });
  };

  async function editarFaturamentoMedico(codFatura, valor, pagamento) {
    try {
      const res = await api.put(`faturamento/valor`, {
        codFatura,
        valor,
        pagamento,
      });
      const updatedFechamento = fechamento.map(item =>
        item.CODFATURA === Number(codFatura)
          ? { ...item, VLRLAUDO: valor, ARQUIVO_URI: pagamento }
          : item
      );
      dispatch(faturamentoSuccess({ fechamento: updatedFechamento }));
      toast.success(res.data.message);
      setEditFatModal(false);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  }

  const relatorioAgrupado = useMemo(
    () => agruparDadosPorMedicoEData(filterFechamento),
    [filterFechamento]
  );

  const totalPorMedico = useMemo(
    () => calcularTotalPorMedico(relatorioAgrupado),
    [relatorioAgrupado]
  );

  const valorTotalGeral = useMemo(() => {
    return Object.values(totalPorMedico).reduce((total, medico) => {
      return (
        total +
        Object.values(medico).reduce((subTotal, valor) => subTotal + valor, 0)
      );
    }, 0);
  }, [totalPorMedico]);

  const generatePDF = (relatorioAgrupado, totalPorMedico, valorTotalGeral) => {
    const doc = new jsPDF();

    doc.setFontSize(16);
    doc.text(`FECHAMENTO DIÁRIO - ${dataFormatada}`, 14, 22);
    doc.setFontSize(12);

    let x = 14;
    let y = 30;

    const lineHeight = 6;
    const pageWidth = doc.internal.pageSize.getWidth();
    const marginRight = 14;

    medicosSorted.forEach(agenda => {
      const isChecked = selectedMedico.includes(agenda.codigo);
      const text = `${agenda.medico}`;
      const textWidth = doc.getTextWidth(text) + 10; // Adiciona um pouco de espaço para o checkbox

      if (x + textWidth > pageWidth - marginRight) {
        x = 14;
        y += lineHeight;
      }

      doc.rect(x, y - 4, 4, 4); // Desenha o checkbox
      if (isChecked) {
        doc.setFillColor(0, 0, 0); // Define a cor de preenchimento como preto
        doc.rect(x, y - 4, 4, 4, 'F'); // Preenche o checkbox se o médico estiver selecionado
      }
      doc.text(text, x + 6, y);
      x += textWidth;
    });

    y += 5; // Adiciona um espaço antes da tabela

    const allData = [];

    Object.keys(relatorioAgrupado).forEach(medico => {
      allData.push([
        {
          content: `Médico: ${medico.split('-')[1]}`,
          colSpan: 4,
          styles: { halign: 'center', fontStyle: 'bold' },
        },
      ]);

      Object.keys(relatorioAgrupado[medico]).forEach(data => {
        relatorioAgrupado[medico][data].forEach(item => {
          allData.push([
            item.NOMEPAC,
            item.DESCPROCEDI,
            item.VLRLAUDO.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }),
            item.ARQUIVO_URI ?? '',
          ]);
        });
      });

      allData.push([
        {
          content: `Total: ${Object.values(
            totalPorMedico[medico]
          )[0].toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}`,
          colSpan: 4,
          styles: { halign: 'right', fontStyle: 'bold' },
        },
      ]);
    });

    doc.autoTable({
      body: allData,
      theme: 'grid',
      startY: y,
      styles: { fontSize: 9 },
      margin: { top: 30 },
    });

    doc.setFontSize(12);
    doc.text(
      `Valor total geral: ${valorTotalGeral.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })}`,
      14,
      doc.autoTable.previous.finalY + 10
    );

    // OBS TEXTBOX
    const textBoxY = doc.autoTable.previous.finalY + 20;
    doc.text('Observações:', 14, textBoxY);
    doc.rect(14, textBoxY + 5, pageWidth - 28, 90); // Desenha a caixa de texto
    doc.text(observacoes, 16, textBoxY + 15, { maxWidth: pageWidth - 32 }); // Adiciona o texto das observações

    const pdfUrl = doc.output('bloburl');
    window.open(pdfUrl, '_blank');
  };

  return (
    <>
      <Card id="cardHeader" style={{ marginTop: 10 }}>
        <Card.Header className="text-center">
          <Card.Title className={styles.titleReport}>
            FECHAMENTO DIÁRIO - {dataFormatada}
          </Card.Title>
        </Card.Header>
        <Card.Body className="text-center">
          {medicosSorted.map(agenda => (
            <Form.Check
              id={agenda.codigo}
              key={agenda.codigo}
              inline
              checked={selectedMedico.includes(agenda.codigo)}
              type="checkbox"
              label={agenda.medico}
              size="sm"
              color="outline-success"
              onClick={() => handleSelectMedico(agenda)}
            />
          ))}
        </Card.Body>
        <div className="d-grid gap-2 text-center">
          <Button onClick={() => setSelectedMedico([])}>Limpar Filtros</Button>{' '}
          <Button
            variant="warning"
            onClick={() =>
              generatePDF(relatorioAgrupado, totalPorMedico, valorTotalGeral)
            }
          >
            Imprimir
          </Button>
        </div>
        {Object.keys(relatorioAgrupado).map(medico => (
          <FaturamentoMedTable
            key={medico}
            medico={medico}
            data={relatorioAgrupado[medico]}
            totalPorMedico={totalPorMedico}
          />
        ))}
        <div style={{ textAlign: 'center', marginTop: 5 }}>
          <strong>
            Total geral:{' '}
            {valorTotalGeral.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </strong>
        </div>
        <strong>Observações</strong>
        <Form.Control
          as="textarea"
          rows={2}
          style={{ marginBottom: 10 }}
          value={observacoes}
          onChange={e => setObservacoes(e.target.value)}
        />
      </Card>

      <Modal show={editFatModal}>
        <Modal.Header>
          <Modal.Title>Editar faturamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p>
              <strong>Nome do paciente:</strong> {nomePaciente}
            </p>
            <p>
              <strong>Procedimento:</strong> {procedimento}
            </p>
          </div>
          <div>
            <strong>Valor: </strong>
            <input
              type="number"
              value={valorFat}
              onChange={e => setValorFat(parseFloat(e.target.value))}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <strong>Forma de pagamento: </strong>
            <input
              type="text"
              value={formaPagamento}
              onChange={e => setFormaPagamento(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setEditFatModal(false)}>
            Fechar
          </Button>
          <Button
            onClick={() =>
              editarFaturamentoMedico(idFat, valorFat, formaPagamento)
            }
          >
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
