import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  Image,
  Spinner,
  Alert,
} from 'react-bootstrap';
import api from '../../../services/api';

export default function PublicEcg() {
  const { token } = useParams();
  const [ecgData, setEcgData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEcgData = async () => {
      try {
        const response = await api.get(`/report/${token}`);
        console.log('response:', response.data);
        setEcgData(response.data.ecg[0]);
      } catch (error) {
        console.error('Error fetching ECG data:', error);
        setError('Error fetching ECG data. Please try again later.');
      }
    };

    fetchEcgData();
  }, [token]);

  if (error) {
    return (
      <Container className="mt-5">
        <Alert variant="danger">{error}</Alert>
      </Container>
    );
  }

  if (!ecgData) {
    return (
      <Container className="mt-5 text-center">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return (
    <Container className="mt-2">
      <Card>
        <Card.Header className="text-center">
          <Card.Title>LAUDO DE ELETROCARDIOGRAMA - {ecgData.CODECG}</Card.Title>
        </Card.Header>
        <Card.Body>
          <p>
            <strong>Nome do Paciente:</strong> {ecgData.CODPAC} -{' '}
            {ecgData.NOMEPAC}
          </p>
          <p>
            <strong>Data de Nasc:</strong> {ecgData.dataNasc}
          </p>
          <p>
            <strong>Sexo:</strong> {ecgData.SEXOPAC}
          </p>
          <p>
            <strong>Idade:</strong> {ecgData.idadePAC} anos
          </p>
          <p>
            <strong>Convênio:</strong> {ecgData.SIGLACONV}
          </p>
          <p>
            <strong>Data do Exame:</strong> {ecgData.dataEcg}
          </p>
          <p>
            <strong>Médico que Realizou:</strong> Dr. {ecgData.Realizou}
          </p>
          <p>
            <strong>Médico que Solicitou:</strong> Dr. {ecgData.Solicitou}
          </p>
          <Image src={ecgData.ARQUIVO_URL} alt="ECG Imagem" fluid />
          <Row className="mt-4">
            <Col>
              <h5>Descrição</h5>
              <p>{ecgData.OBSERVACAO}</p>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
}
