const convenioAgendaPac = [
  {
    codConv: 23,
    sigla: 'ACE - ASSOCIACÃO COMERCIAL EMPRESARIAL DE POMPÉIA',
  },
  {
    codConv: 7,
    sigla: 'AFRESP',
  },
  {
    codConv: 20,
    sigla: 'APAS',
  },
  {
    codConv: 43,
    sigla: 'BRADESCO SAÚDE',
  },
  {
    codConv: 1,
    sigla: 'CABESP',
  },
  {
    codConv: 2,
    sigla: 'CASSI',
  },
  {
    codConv: 4,
    sigla: 'ECONOMUS',
  },
  {
    codConv: 9,
    sigla: 'GEAP',
  },
  {
    codConv: 801,
    sigla: 'MEDISERVICE',
  },
  {
    codConv: 25,
    sigla: 'PARTICULAR',
  },
  {
    codConv: 13,
    sigla: 'SINDICATO PREFEITURA DE POMPÉIA',
  },
  {
    codConv: 14,
    sigla: 'UNIMED NACIONAL/INTERCÂMBIO',
  },
  {
    codConv: 1414,
    sigla: 'UNIMED MARÍLIA',
  },
  {
    codConv: 65,
    sigla: 'UNIMED JACTO',
  },
  /*   {
    codConv: 3,
    sigla: 'VIVEST',
  }, */
];

export default convenioAgendaPac;
