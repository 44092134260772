import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Button, Card, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import md5 from 'md5';
import Loading from '../../../pages/Index/Loading';
import api from '../../../services/api';
import s3 from '../../../util/s3';
import header from '../../../assets/icm-header.jpg';
import blank from '../../../assets/icm-blank.jpg';

import history from '../../../services/history';

export default function ReportHolter() {
  const { holter } = useSelector(state => state.holter);
  const { loading, printing } = useSelector(state => state.contexto);
  const [urls, setURLs] = useState([]);
  const [files, setFiles] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [showUpload, setShowUpload] = useState(false);
  const [loadingUploadFile, setLoadingUploadFile] = useState(false);
  const [setAtividade] = useState();

  useEffect(() => {
    s3.listObjectsV2(
      { Bucket: 'prevencor', Prefix: `holter/${holter[0].CODHOLTER}` },
      (err, data) => {
        if (err) toast.error('Erro ao buscar arquivos. Entre em contato.');
        else setFiles(data.Contents.filter(item => item.Key.includes('.pdf')));
      }
    );
  }, [holter]);

  useEffect(() => {
    async function getFiles(f) {
      f = f.Key.split('/');

      return await api.get(
        `https://j87jesh814.execute-api.us-east-1.amazonaws.com/dev/image-upload/folder/holter/laudo/${holter[0]?.CODHOLTER}/key/${f[2]}`
      );
    }

    async function showFiles() {
      const array = [];

      for (let file of files) {
        const url = await getFiles(file);
        array.push(url);
      }

      setURLs(array);
    }

    showFiles();
  }, [holter, files]);

  function removeDiv(divName) {
    let newdiv = document.createElement('div');
    let originalContents = document.body.innerHTML;
    newdiv.innerHTML = originalContents;
    document.getElementById(divName).remove(newdiv);
    window.print();
    document.body.innerHTML = originalContents;
  }

  async function handleUploadFile(file) {
    if (!file) return toast.warn('Selecione um arquivo!');

    const encodedFilename = encodeURIComponent(file.name);

    try {
      const { data } = await api.put(
        `https://j87jesh814.execute-api.us-east-1.amazonaws.com/dev/image-upload/folder/holter/laudo/${holter[0]?.CODHOLTER}/key/${encodedFilename}`
      );

      await fetch(data.preSignedUrl, { method: 'PUT', body: file });
      setLoadingUploadFile(true);
    } catch (error) {
      toast.error('Erro ao fazer upload do arquivo!');
    }
  }

  async function confirmUpload() {
    for (const file of filesToUpload) {
      await handleUploadFile(file);
    }

    setLoadingUploadFile(true);
  }

  function handleAttachFile(atividade) {
    setShowUpload(true);
    setAtividade(atividade);
  }

  function closeFileModal() {
    setShowUpload(false);
    setLoadingUploadFile(false);
    setFilesToUpload([]);
  }

  function prefixo() {
    if (
      holter[0].CODREALIZOU === 1 ||
      holter[0].CODREALIZOU === 5 ||
      holter[0].CODREALIZOU === 2796 ||
      holter[0].CODREALIZOU === 958
    ) {
      return 'Prof. Dr. ';
    } else {
      return 'Dr. ';
    }
  }

  return (
    <>
      <Card>
        {printing ? (
          <div className="mt-3" style={{ textAlign: 'center' }}>
            <img width="550" height="100" src={header} alt="header" />
          </div>
        ) : (
          <div className="mt-3" style={{ textAlign: 'center' }}>
            <img width="550" height="100" src={blank} alt="header" />
          </div>
        )}
        <Card.Header className="text-center">
          <Card.Title>LAUDO DE HOLTER</Card.Title>
        </Card.Header>

        <Card.Body>
          <Form noValidate>
            <strong className="mr-auto">Nome do Paciente: </strong>
            {`${holter[0]?.CODPAC} - ${holter[0]?.NOMEPAC}`}
            <br></br>
            <strong className="mr-auto">Data de Nasc: </strong>
            {`${holter[0].dataNasc}`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Sexo: </strong>
            {`${holter[0].SEXOPAC}`}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Idade: </strong>
            {`${holter[0].idadePAC} anos`}
            <br></br>
            <strong className="mr-auto">Convênio: </strong>
            {holter[0].DESCRICONV}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Cod Holter: </strong>
            {holter[0].CODHOLTER}&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Data do Exame: </strong>
            {holter[0].dataHolter}
            <br></br>
            <strong className="mr-auto">
              Médico que Realizou: {prefixo()}{' '}
            </strong>
            {`${holter[0].Realizou}`}
            <br></br>
            <strong className="mr-auto">
              Médico que Solicitou: {prefixo()}{' '}
            </strong>
            {`${holter[0].NOMEMED}`}
            <hr></hr>
            {urls.map(url => (
              <div style={{ textAlign: 'center', display: 'block' }}>
                <embed
                  src={url.data.preSignedUrl}
                  width="800px"
                  height="900px"
                />
              </div>
            ))}
            <Card className="mt-3 text-center">
              <Card.Body>
                <Card.Text>
                  <i className="mr-auto">
                    Conferido e assinado eletronicamente por:
                  </i>
                  <strong className="mr-auto"> Dr. {holter[0].Realizou}</strong>
                  <br></br>
                  <i className="mr-auto">
                    Assinatura Digital: {md5(JSON.stringify(holter[0]))}
                  </i>
                </Card.Text>
              </Card.Body>
              <Card.Footer id="removeMe" className="text-muted">
                <Button size="lg" onClick={() => removeDiv('removeMe')}>
                  Imprimir
                </Button>{' '}
                <Button
                  variant="warning"
                  size="lg"
                  onClick={() => handleAttachFile(holter)}
                >
                  Anexar
                </Button>{' '}
                <Button
                  variant="success"
                  size="lg"
                  onClick={() => history.push('/agenda')}
                >
                  Fechar
                </Button>
                <br />
              </Card.Footer>
            </Card>
          </Form>
        </Card.Body>
      </Card>

      {loading ? <Loading /> : null}

      {showUpload ? (
        <Modal show={showUpload} onHide={closeFileModal}>
          <Modal.Header closeButton>
            <Modal.Title>Anexar arquivo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label for="laudos">Selecione pelo menos 1 arquivo</label>
            <br />
            <input
              type="file"
              style={{ color: 'white' }}
              multiple
              onChange={e => setFilesToUpload(Array.from(e.target.files))}
            />
            <ul>
              {filesToUpload && filesToUpload.map(file => <li>{file.name}</li>)}
            </ul>
            <div>
              <br />
              {loadingUploadFile ? (
                <text style={{ color: 'green', marginRight: '100px' }}>
                  Upload finalizado com sucesso!
                </text>
              ) : null}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={confirmUpload}>
              Confirmar
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  );
}
