import { toast } from 'react-toastify';
import { addDays, parseISO, format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import api from './api';
import {
  loadingOpen,
  loadingClose,
} from '../redux/features/context/contextSlice';

const fetchAgendas = async ({
  idMedico,
  dateAgendaFormatted,
  setAgendamentos,
  setCount,
  setIdMedico,
  setAgendaMed,
  funcionario,
  dispatch,
}) => {
  let c = 0;
  dispatch(loadingOpen());
  try {
    let response;
    if (idMedico) {
      response = await api.get(
        `medico/agendas?id=${idMedico}&dt=${dateAgendaFormatted}`
      );
    } else if (funcionario) {
      setIdMedico(5);
      setAgendaMed('Dr Fabio');
      response = await api.get(
        `medico/agendas?id=${5}&dt=${dateAgendaFormatted}`
      );
    } else {
      return toast.warn('Médico não identificado!');
    }

    const data = response?.data?.map(agenda => ({
      ...agenda,
      dataFormatada: format(
        addDays(parseISO(agenda.DATAAGENDA), 1),
        'dd/MM/yyyy',
        { locale: pt }
      ),
      counter: (c += 1),
    }));

    setAgendamentos(data);
    setCount(c);
  } catch (error) {
    toast.error(error.response.data.message);
  } finally {
    dispatch(loadingClose());
  }
};

const agendaAfterPost = Agenda => {
  let obs = Agenda.OBSERVACAO || '';

  // Verifica se o médico é válido
  if (Agenda.CODMED !== 0) {
    // Verifica se o médico é 1 e o procedimento é 1
    if (Agenda.CODMED === 1 && Agenda.codProcedi === 1) {
      const conveniosAtendidos = [20, 4, 5, 8, 62, 2, 7, 11, 12, 23, 3, 43, 13];
      if (conveniosAtendidos.includes(Agenda.codConv)) {
        toast.info(`ALERTA: Dr. BRAGA não atende convênio ${Agenda.codConv}`);
        obs += '-Médico não atende o convênio';
      }
    }

    // Verifica se o médico é 3283 e o procedimento é 1
    if (Agenda.CODMED === 3283 && Agenda.codProcedi === 1) {
      if (Agenda.codConv === 92) {
        toast.info(`ALERTA: Médico não atende convênio ${Agenda.codConv}`);
        obs += '-Médico não atende o convênio';
      }
    }

    // Verifica se o médico é 5 e o procedimento é 1
    if (Agenda.CODMED === 5 && Agenda.codProcedi === 1) {
      if (Agenda.codConv === 36) {
        toast.info(`ALERTA: Médico não atende convênio ${Agenda.codConv}`);
        obs += '-Médico não atende o convênio';
      }
    }

    // Verifica se o médico é 2030 e o procedimento é 1
    if (Agenda.CODMED === 2030 && Agenda.codProcedi === 1) {
      if (Agenda.codConv === 732) {
        toast.info(`ALERTA: Médico não atende convênio ${Agenda.codConv}`);
        obs += '-Médico não atende o convênio';
      }
    }

    // Verifica se o médico é 1981 e o procedimento é 1 para o convênio 25
    if (
      Agenda.CODMED === 1981 &&
      Agenda.codProcedi === 1 &&
      Agenda.codConv === 25
    ) {
      toast.info(
        'ALERTA: PAGTO EM DINHEIRO OU PIX (21964622840) DIRETO P/ Médica'
      );
      obs += '-PAGTO DINHEIRO OU PIX (21964622840)';
    }

    // Verifica outros médicos e procedimentos com seus respectivos convenios
    if (
      Agenda.CODMED === 957 &&
      (Agenda.codProcedi === 2 || Agenda.codProcedi === 8)
    ) {
      if (Agenda.codConv === 36) {
        toast.info(`ALERTA: Médico não atende convênio ${Agenda.codConv}`);
        obs += '-Médico não atende o convênio';
      }
    }

    // Verifica o médico 2796 para convenios específicos
    if (Agenda.CODMED === 2796 && Agenda.codProcedi === 1) {
      const conveniosValidos = [2, 4, 36, 43, 732, 108, 69, 92, 9];
      if (conveniosValidos.includes(Agenda.codConv)) {
        toast.info(`ALERTA: Médico não atende convênio ${Agenda.codConv}`);
        obs += '-Médico não atende o convênio';
      }
    }
  }

  // Verifica o procedimento 5 e seu convênio
  if (Agenda.codProcedi !== 0) {
    if (
      (Agenda.codProcedi === 7 && Agenda.codConv === 5) ||
      (Agenda.codProcedi === 5 && Agenda.codConv === 108) ||
      (Agenda.codProcedi === 5 && Agenda.codConv === 5) ||
      (Agenda.codProcedi === 5 && Agenda.codConv === 1)
    ) {
      toast.info(
        `ALERTA: Solicitar autorização 5 dias antes para Procedimento ${Agenda.codProcedi}`
      );
      obs += '-Autorizar primeiro, e depois Agendar';
    }
  }

  // Verifica o procedimento 675 e o convênio 69
  if (
    Agenda.codProcedi === 675 &&
    (Agenda.codConv === 69 || Agenda.codConv === 2 || Agenda.codConv === 3)
  ) {
    toast.info(
      `ALERTA: Não realizamos este Procedimento ${Agenda.codProcedi} para o Convênio ${Agenda.codConv}`
    );
    obs += '-Não Realiza o Procedimento!';
  }

  if (
    Agenda.codProcedi === 801 &&
    (Agenda.codConv === 1 || Agenda.codConv === 2)
  ) {
    toast.info(
      `ALERTA: Não realizamos este Procedimento ${Agenda.codProcedi} para o Convênio ${Agenda.codConv}`
    );
    obs += '-Não Realiza o Procedimento!';
  }

  // segunda parte

  // CABESP - 1
  if (Agenda.codConv === 1) {
    if ([675, 720, 804, 805].includes(Agenda.codProcedi)) {
      toast.info('ALERTA: Convênio não Autoriza ' + Agenda.codProcedi);
      obs += '-Convênio não Autoriza!';
    }
  }

  // CASSI - 2
  if (Agenda.codConv === 2) {
    if ([2, 675, 720, 750, 801, 803, 804, 805].includes(Agenda.codProcedi)) {
      toast.info('ALERTA: Convênio não Autoriza ' + Agenda.codProcedi);
      obs += '-Convênio não Autoriza!';
    }
    if (
      [4, 5, 6, 7, 11, 12, 13, 15, 17, 712, 713, 714, 715, 810].includes(
        Agenda.codProcedi
      )
    ) {
      toast.info(
        'ALERTA: Solicitar/Reter pedido médico e Nr Carteirinha ' +
          Agenda.codProcedi
      );
      obs += '-Solicitar/Reter pedido médico e Nr Carteirinha!';
    }
  }

  // CESP - 3
  if (Agenda.codConv === 3) {
    if ([2, 675, 720, 750].includes(Agenda.codProcedi)) {
      toast.info('ALERTA: Convênio não Autoriza ' + Agenda.codProcedi);
      obs += '-Convênio não Autoriza!';
    }
    if ([1, 3, 4, 5, 6, 7, 11, 12, 13, 15, 17].includes(Agenda.codProcedi)) {
      toast.info(
        'ALERTA: Não Atendemos esse Procedimento ' + Agenda.codProcedi
      );
      obs += '-Não Atendemos esse Procedimento!';
    }
  }

  // ECONOMUS - 4
  if (Agenda.codConv === 4) {
    if ([2, 675, 720, 750, 801, 803].includes(Agenda.codProcedi)) {
      toast.info('ALERTA: Convênio não Autoriza ' + Agenda.codProcedi);
      obs += '-Convênio não Autoriza!';
    }
    if (
      [5, 6, 7, 11, 12, 13, 15, 712, 713, 714, 715, 804, 805, 810, 17].includes(
        Agenda.codProcedi
      )
    ) {
      toast.info(
        'ALERTA: Solicitar/Reter pedido médico e Nr Carteirinha ' +
          Agenda.codProcedi
      );
      obs += '-Solicitar/Reter pedido médico e Nr Carteirinha!';
    }
  }

  // Verificar convênios 5 e 12
  if (Agenda.codConv === 5 || Agenda.codConv === 12) {
    toast.info('ALERTA: Não atendemos esse convênio!');
    obs += '-Não atendemos esse convênio!!';
    // Chama a função para atualizar a agenda com a observação
  }

  // Verificar convênio 7 (AFRESP)
  if (Agenda.codConv === 7) {
    // Verificar procedimentos não autorizados
    if ([803, 804, 805].includes(Agenda.codProcedi)) {
      toast.info('ALERTA: Convênio não Autoriza ' + Agenda.codProcedi);
      obs += '-Convênio não Autoriza!';
    }

    // Verificar procedimentos que requerem pedido médico e número da carteirinha
    if (
      [
        2,
        4,
        5,
        6,
        7,
        11,
        12,
        13,
        15,
        17,
        712,
        713,
        714,
        715,
        675,
        720,
        810,
        750,
      ].includes(Agenda.codProcedi)
    ) {
      toast.info(
        'ALERTA: Solicitar/Reter pedido médico e Nr Carteirinha ' +
          Agenda.codProcedi
      );
      obs += '-Solicitar/Reter pedido médico e Nr Carteirinha!';
    }
  }

  // Verificar convênio 9 (GEAP)
  if (Agenda.codConv === 9) {
    // Verificar procedimentos não autorizados
    if ([5, 17, 720, 750, 801, 803, 804, 805].includes(Agenda.codProcedi)) {
      toast.info('ALERTA: Convênio não Autoriza ' + Agenda.codProcedi);
      obs += '-Convênio não Autoriza!';
    }

    // Verificar procedimentos que requerem pedido médico e número da carteirinha
    if ([6, 11, 12, 675, 712, 810].includes(Agenda.codProcedi)) {
      toast.info(
        'ALERTA: Solicitar/Reter pedido médico e Nr Carteirinha ' +
          Agenda.codProcedi
      );
      obs += '-Solicitar/Reter pedido médico e Nr Carteirinha!';
    }
  }

  // BRADESCO - 43
  if (Agenda.codConv === 43) {
    if ([2, 675, 720].includes(Agenda.codProcedi)) {
      toast.info('ALERTA: Convênio não Autoriza ' + Agenda.codProcedi);
      obs += '-Convênio não Autoriza!';
    }
    if (
      [5, 6, 7, 11, 12, 13, 15, 801, 804, 805, 15].includes(Agenda.codProcedi)
    ) {
      toast.info(
        'ALERTA: Solicitar/Reter pedido médico e Nr Carteirinha ' +
          Agenda.codProcedi
      );
      obs += '-Solicitar/Reter pedido médico e Nr Carteirinha!';
    }
  }

  // CONV - 14/65/68/70
  if ([14, 65, 68, 70].includes(Agenda.codConv) && Agenda.codProcedi === 675) {
    toast.info('ALERTA: Convênio não Autoriza ' + Agenda.codProcedi);
    obs += '-Convênio não Autoriza!';
  }

  // CONV - 108
  if (Agenda.codConv === 108) {
    if ([675, 720, 750].includes(Agenda.codProcedi)) {
      toast.info('ALERTA: Convênio não Autoriza ' + Agenda.codProcedi);
      obs += '-Convênio não Autoriza!';
    }
  }

  // CONV - 732
  if (Agenda.codConv === 732) {
    if ([801].includes(Agenda.codProcedi)) {
      toast.info('ALERTA: Convênio não Autoriza ' + Agenda.codProcedi);
      obs += '-Convênio não Autoriza!';
    }
    if ([2].includes(Agenda.codProcedi)) {
      toast.info('ALERTA: Não realizamos pelo Convênio ' + Agenda.codProcedi);
      obs += '-Não realizamos pelo Convênio!';
    }
  }

  // CONV - 801
  if (Agenda.codConv === 801) {
    if ([675, 720].includes(Agenda.codProcedi)) {
      toast.info('ALERTA: Convênio não Autoriza ' + Agenda.codProcedi);
      obs += '-Convênio não Autoriza!';
    }
    if ([4, 7, 17].includes(Agenda.codProcedi)) {
      toast.info(
        'ALERTA: Solicitar/Reter pedido médico e Nr Carteirinha ' +
          Agenda.codProcedi
      );
      obs += '-Solicitar/Reter pedido médico e Nr Carteirinha!';
    }
  }

  return obs;
};

export { fetchAgendas, agendaAfterPost };
