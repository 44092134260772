/* eslint-disable no-bitwise */
import React, { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { makeStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import { IoMdClipboard } from 'react-icons/io';
import {
  format,
  subDays,
  subHours,
  addDays,
  addMonths,
  parseISO,
} from 'date-fns';
import pt from 'date-fns/locale/pt';
import { ptBR } from 'date-fns/locale';
import validarCPF from 'validar-cpf';
import { Button, Alert, Card, Col, Container } from 'react-bootstrap';
import styles from '../../../styles/styles.module.css';
import api from '../../../services/api';
import medicoAgendaPac from '../../../util/medicoAgendaPac';
import convenioAgendaPac from '../../../util/convenioAgendaPac';
import formatarHora from '../../../util/formatarHora';
import header from '../../../assets/icm-header.jpg';

const useStyles = makeStyles(() => ({}));

function getSteps() {
  return [
    'Selecione um Convênio',
    'Selecione um Médico',
    'Selecione uma Data e Horário',
    'Conferir o Agendamento',
    'Concluir o Agendamento',
  ];
}

function getStepContent(
  step,
  classes,
  selectedDate,
  selectedIdxMed,
  selectedIdxConv,
  selectedIdxAgenda,
  handleDateChange,
  handleMedicoClick,
  handleConvenioClick,
  medicos,
  paciente,
  convenios,
  handlePrevDay,
  handleNextDay,
  dateFormatted,
  horariosAgenda,
  handleHorarioClick,
  formatarHora,
  selectedHorario,
  handleChangeDtAgenda,
  dateAgenda,
  nextDates,
  nomeMedico,
  siglaConvenio,
  horario,
  handleConfirm,
  agenda,
  handleReset
) {
  switch (step) {
    case 0:
      return (
        <List dense component="nav" aria-label="Lista de Convênios">
          {convenios.map((convenio, idxConv) => (
            <ListItem
              dense
              button
              key={convenio.codConv}
              selected={selectedIdxConv === idxConv}
              onClick={e =>
                handleConvenioClick(
                  e,
                  idxConv,
                  convenio.codConv,
                  convenio.sigla
                )
              }
            >
              <ListItemText>{convenio.sigla}</ListItemText>
            </ListItem>
          ))}
        </List>
      );
    case 1:
      return (
        <List dense component="nav" aria-label="Lista de Médicos">
          {medicos.map((medico, idxMed) => (
            <ListItem
              dense
              button
              key={medico.codigo}
              selected={selectedIdxMed === idxMed}
              onClick={e =>
                handleMedicoClick(e, idxMed, medico.codigo, medico.medico)
              }
            >
              <ListItemText>{medico.medico}</ListItemText>
            </ListItem>
          ))}
        </List>
      );
    case 2:
      return (
        <>
          <DatePicker
            selected={dateAgenda}
            onChange={handleChangeDtAgenda}
            inline
            locale={ptBR}
            minDate={new Date()}
            maxDate={addMonths(new Date(), 12)}
            dayClassName={date => {
              const isAvailable = nextDates.some(d => {
                const serverDate = new Date(d);
                return (
                  date.getUTCFullYear() === serverDate.getUTCFullYear() &&
                  date.getUTCMonth() === serverDate.getUTCMonth() &&
                  date.getUTCDate() === serverDate.getUTCDate()
                );
              });
              return isAvailable ? styles.nextAppts : undefined;
            }}
          />

          {horariosAgenda.length !== 0 ? (
            horariosAgenda.map((a, idxAgenda) => (
              <div key={a.CODAGENDA} className="m-2">
                <ListItem
                  dense
                  button
                  key={a.CODAGENDA}
                  selected={selectedIdxAgenda === idxAgenda}
                  onClick={e => handleHorarioClick(e, idxAgenda, a.HORARIO, a)}
                >
                  <ListItemText>{formatarHora(a.HORARIO)}</ListItemText>
                  <ListItemIcon>
                    <IoMdClipboard />
                  </ListItemIcon>
                </ListItem>
                <Divider />
              </div>
            ))
          ) : (
            <Alert variant="danger">
              NÃO TEM HORÁRIO DE CONSULTA PARA ESTE DIA SELECIONADO!
            </Alert>
          )}
        </>
      );
    case 3:
      return (
        <Alert variant="primary">
          <p>
            <cite>
              {paciente.nome}, estamos quase concluindo o seu Agendamento
              online.
            </cite>
          </p>
          Clique em <strong>AGENDAR</strong> e confira os dados do seu
          Agendamento antes de confirmar o Agendamento!
        </Alert>
      );
    case 4:
      return (
        <Col sm={6}>
          <Card className="m-1">
            <Card.Header>
              <Card.Title>Confirmação de Agendamento!</Card.Title>
            </Card.Header>
            <Card.Body>
              <ul>
                <li>
                  {paciente.nome}, estamos <i>"finalizando"</i> o seu
                  Agendamento, por favor confira os dados abaixo e tecle em{' '}
                  <strong>CONFIRMAR</strong>!
                </li>
                <blockquote>
                  <cite>
                    Agendamento marcado com {nomeMedico} no Convênio{' '}
                    {siglaConvenio} para {dateFormatted} às{' '}
                    {formatarHora(horario)}. Chegar com 15 minutos de
                    antecedência.
                  </cite>
                </blockquote>
                <li>
                  LOCAL: Av. Vicente Ferreira 780 – ao lado do P.S. da Santa
                  Casa
                </li>
                <li>Temos estacionamento próprio na Rua Amazonas, 777.</li>
                <li>Você receberá um SMS de confirmação do seu agendamento</li>
              </ul>
            </Card.Body>
            <Card.Footer className="justify-content-start">
              <Button
                variant="success"
                size="sm"
                onClick={e => handleConfirm(e, agenda)}
              >
                <strong>Confirmar</strong>
              </Button>
              {'  '}
              <Button variant="warning" size="sm" onClick={handleReset}>
                <strong>Alterar</strong>
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      );
    default:
      return 'N/A';
  }
}

export default function Agendamento() {
  const location = useLocation();
  const [paciente, setPaciente] = useState({ codPac: 1000 });
  const [codMedico, setCodMedico] = useState();
  const [nomeMedico, setNomeMedico] = useState();
  const [codConvenio, setCodConvenio] = useState();
  const [siglaConvenio, setSiglaConvenio] = useState();
  const [dateAgenda, setDateAgenda] = useState(new Date());
  const [horariosAgenda, setHorariosAgenda] = useState([]);
  const [horario, setHorario] = useState();
  const [agenda, setAgenda] = useState([]);
  const [selectedHorario, setSelectedHorario] = useState(false);
  const [selectedIdxMed, setSelectedIdxMed] = useState();
  const [selectedIdxConv, setSelectedIdxConv] = useState();
  const [selectedIdxAgenda, setSelectedIdxAgenda] = useState();
  const [medicos, setMedicos] = useState(medicoAgendaPac);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [activeStep, setActiveStep] = useState(0);
  const [isMensagem, setIsMensagem] = useState(false);
  const [isAgendamento, setIsAgendamento] = useState(false);
  const [datesAvailableIn30Days, setDatesAvailableIn30Days] = useState([]);
  const [isProtocolo, setIsProtocolo] = useState(false);
  const [isError422, setIsError422] = useState(false);
  const [alert, setAlert] = useState({
    show: false,
    variant: 'primary',
    mensagem: '',
  });
  const [payload, setPayload] = useState({
    mensagem: '',
    celular: '',
    protocolo: '',
  });
  const steps = getSteps();
  const classes = useStyles();
  const dateFormatted = useMemo(
    () => format(dateAgenda, "dd 'de' MMMM 'de' yyyy", { locale: pt }),
    [dateAgenda]
  );
  const dateAgendaFormatted = useMemo(
    () => subHours(dateAgenda, 3).toISOString(),
    [dateAgenda]
  );
  const searchParams = new URLSearchParams(location.search);
  const cpf = searchParams.get('cpf');

  useEffect(() => {
    const fetchDatesWithAppointments = async () => {
      if (!codConvenio || !codMedico || activeStep !== 2) return;

      try {
        const response = await api.get(`/next-appointments/${codMedico}`);
        const dates = response.data.map(item => parseISO(item.DATAAGENDA));
        setDatesAvailableIn30Days(dates);
      } catch (error) {
        toast.error(error?.response?.data?.error);
      }
    };

    fetchDatesWithAppointments();
  }, [codConvenio, codMedico, activeStep]);

  useEffect(() => {
    async function loadPaciente() {
      try {
        setIsAgendamento(false);
        setIsProtocolo(false);

        if (!validarCPF(cpf)) {
          setIsMensagem(true);
          return toast.error('CPF do Paciente é inválido.');
        }

        setIsMensagem(false);
        setIsAgendamento(true);
        const response = await api.get(`paciente-cpf/${cpf}`);
        setPaciente(response.data.paciente);
      } catch (error) {
        toast.error(error?.response?.data?.error);
        setIsMensagem(true);
      }
    }

    loadPaciente();
  }, []);

  useEffect(() => {
    async function loadAgendaAberta() {
      if (codMedico) {
        const response = await api.get(
          `medico/agendaaberta?id=${codMedico}&dt=${dateAgendaFormatted}`
        );
        setHorariosAgenda(response.data);
      }
    }

    loadAgendaAberta();
  }, [
    agenda,
    codMedico,
    dateAgenda,
    dateAgendaFormatted,
    horario,
    paciente.codPac,
  ]);

  function handleNext() {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  function handleBack() {
    setAlert({ show: false, variant: '', mensagem: '' });
    if (activeStep === 1) setMedicos(medicoAgendaPac);
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  function handlePrev() {
    toast.error('Selecione uma opção!');
    setActiveStep(prevActiveStep => prevActiveStep);
  }

  function handlePrevDay() {
    setDateAgenda(subDays(dateAgenda, 1));
  }

  function handleNextDay() {
    setDateAgenda(addDays(dateAgenda, 1));
  }

  function handleChangeDtAgenda(dtAgenda) {
    setDateAgenda(dtAgenda);
  }

  function handleHorarioClick(_, index, horaAgenda, objAgenda) {
    objAgenda.codPac = paciente.codPac;
    objAgenda.situacao = 'Agendada';
    objAgenda.codConv = codConvenio;
    objAgenda.codProcedi = 1;
    objAgenda.atividade = 'CONSULTA';
    objAgenda.protocolo = `ICM-${new Date().getTime()}`;
    setHorario(horaAgenda);
    setAgenda(objAgenda);
    setSelectedHorario(true);
    setSelectedIdxAgenda(index);
    setAlert(prevAlert => ({
      ...prevAlert,
      show: true,
      variant: 'success',
      mensagem: `${
        prevAlert.mensagem
      }Data e Horário: ${dateFormatted} - ${formatarHora(horaAgenda)}h`,
    }));
    handleNext();
  }

  function handleReset() {
    setActiveStep(0);
    setSelectedDate(new Date());
  }

  async function handleConfirm() {
    const telefoneFormatado = paciente.celular
      .replace(/\D/g, '')
      .replace(/^0/, '55');

    const mensagem = `Olá, ${paciente.nome},
Protocolo: ${agenda?.protocolo}
Agendamento marcado com ${nomeMedico} no Convênio ${siglaConvenio} para ${dateFormatted} às ${formatarHora(
      horario
    )}.
Local: Instituto do Coração de Marília - ICM.
Endereço: Av. Vicente Ferreira 780 – ao lado do P.S. da Santa Casa.
Chegar com 15 minutos de antecedência.`;

    setPayload({
      mensagem: encodeURIComponent(mensagem),
      celular: telefoneFormatado,
      protocolo: agenda?.protocolo,
    });

    try {
      await api.put('agendamento-online', {
        agenda,
        sms: mensagem,
        celular: telefoneFormatado,
      });
      toast.success('AGENDAMENTO REALIZADO COM SUCESSO!');
      handleReset();
      setIsProtocolo(true);
      setIsAgendamento(false);
      setIsMensagem(false);
    } catch (err) {
      if (err.response?.status === 422) {
        setAlert({
          show: true,
          variant: 'warning',
          mensagem: `Erro de validação: ${err.response?.data?.error ||
            'Dados inválidos fornecidos.'}!`,
        });
        setIsError422(true);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        setAlert({
          show: true,
          variant: 'danger',
          mensagem: `Erro: ${err.response?.data?.error ||
            'Ocorreu um erro inesperado.'}!`,
        });
      }
      handleReset();
    }
  }

  function checkConvenio(codConv) {
    const listMedicos = [];
    // 25 Particular Todos  - (1/5/958/957/24/2030/2657/2796/3283/1628/3794/4190)
    // 1 Cabesp Todos
    if (codConv === 25 || codConv === 1) {
      medicoAgendaPac.map(medico => {
        if (medico.codigo !== 4161) {
          listMedicos.push(medico);
        }
        setMedicos(listMedicos);
        setCodConvenio(codConv);
        return listMedicos;
      });
    }
    // 14 / 68 /69 /70 Unimed menos 5 Fábio, 1 Braga e 1628 Roberto
    // 2796 dr joao
    if (codConv === 14 || codConv === 68 || codConv === 69 || codConv === 70) {
      medicoAgendaPac.map(medico => {
        if (
          medico.codigo !== 1 &&
          medico.codigo !== 5 &&
          medico.codigo !== 2796 &&
          medico.codigo !== 4161 &&
          medico.codigo !== 1628
        ) {
          listMedicos.push(medico);
        }
        setMedicos(listMedicos);
        setCodConvenio(codConv);
        return listMedicos;
      });
    }
    // 1414 unimed marilia menos 5 Fábio, 1 Braga e 1628 Roberto
    if (codConv === 1414) {
      medicoAgendaPac.map(medico => {
        if (
          medico.codigo !== 1 &&
          medico.codigo !== 5 &&
          medico.codigo !== 4161 &&
          medico.codigo !== 1628
        ) {
          listMedicos.push(medico);
        }
        setMedicos(listMedicos);
        setCodConvenio(14);
        return listMedicos;
      });
    }
    // 65 Unimed Jacto Todos (1/5/958/957/24/2030/2657/2796/3283/3794/4190) menos 1628
    if (codConv === 65) {
      medicoAgendaPac.map(medico => {
        if (medico.codigo !== 1628 && medico.codigo !== 4161) {
          listMedicos.push(medico);
        }
        setMedicos(listMedicos);
        setCodConvenio(codConv);
        return listMedicos;
      });
    }
    // 732 São Francisco Médicos que atendem: 4190 Érica, 3283 Igor,
    // 2657 Marina, 3794 Raphael e 1628 Roberto
    if (codConv === 732) {
      medicoAgendaPac.map(med => {
        if (
          med.codigo === 4190 ||
          med.codigo === 4161 ||
          med.codigo === 2657 ||
          med.codigo === 3794 ||
          med.codigo === 1628
        ) {
          listMedicos.push(med);
        }
        setMedicos(listMedicos);
        setCodConvenio(codConv);
        return listMedicos;
      });
    }
    // 43 Bradesco Saúde Menos 1 Dr.Braga e Dr Joao 2796
    // 2 Cassi Menos Dr.Braga e Dr Joao 2796
    // 4 Economus Menos Dr.Braga1 e Dr Joao 2796
    // 108 - AMIL Menos Dr.Braga1 e Dr Joao 2796
    if (
      codConv === 43 ||
      codConv === 801 ||
      codConv === 2 ||
      codConv === 4 ||
      codConv === 108
    ) {
      medicoAgendaPac.map(med => {
        if (med.codigo !== 1 && med.codigo !== 2796 && med.codigo !== 4161) {
          listMedicos.push(med);
        }
        setMedicos(listMedicos);
        setCodConvenio(codConv);
        return listMedicos;
      });
    }
    // 5 Saúde Caixa Menos Dr.Braga 1
    // 3 Funcesp Menos Dr.Braga1
    // 20 APAS Menos Dr.Braga 1
    // 23 ACE Menos Dr.Braga 1
    // 7 Afresp Menos Dr.Braga 1
    // 13 SSPP Menos Dr.Braga 1
    if (
      codConv === 3 ||
      codConv === 7 ||
      codConv === 13 ||
      codConv === 20 ||
      codConv === 23
    ) {
      medicoAgendaPac.map(med => {
        if (med.codigo !== 1 && med.codigo !== 5 && med.codigo !== 4161) {
          listMedicos.push(med);
        }
        setMedicos(listMedicos);
        setCodConvenio(codConv);
        return listMedicos;
      });
    }

    setAlert({ show: true, variant: 'success', mensagem: `Agendamento: ` });
    return handleNext();
  }

  function handleDateChange(date) {
    setSelectedDate(date);
  }

  function calculaIdade(dateString) {
    const birthday = +new Date(dateString);
    return ~~((Date.now() - birthday) / 31557600000);
  }

  function handleMedicoClick(_, index, codMed, nomeMed) {
    const dtNascimento = Date.parse(paciente.dtNasc);
    const idade = calculaIdade(dtNascimento);

    // Verifica se o médico é Cardio Pediatra
    if ((codMed === 4190 || codMed === 4613 || codMed === 4860) && idade > 14) {
      toast.error(
        `Médico ${nomeMed} é Cardio Pediatra e só atende pacientes até 14 anos. Favor agendar com outro médico da equipe que atenda o seu convênio!`
      );
      handleReset();
      return;
    }

    setAlert(prevAlert => ({
      ...prevAlert,
      show: true,
      variant: 'success',
      mensagem: `${prevAlert.mensagem} Médico: ${nomeMed} - Convênio: ${siglaConvenio} - `,
    }));
    setSelectedIdxMed(index);
    setCodMedico(codMed);
    setNomeMedico(nomeMed);
    handleNext();
  }

  function handleConvenioClick(_, index, codConv, siglaConv) {
    setSelectedIdxConv(index);
    const unimedMarilia = 14;
    const newCodConvenio = codConv === Number(1414) ? unimedMarilia : codConv;

    setCodConvenio(newCodConvenio);
    setSiglaConvenio(siglaConv);
    checkConvenio(codConv);
  }

  return (
    <Container fluid>
      {isProtocolo && (
        <div className="container mt-5">
          <h1 className="mb-4">Agendamento de Consulta</h1>

          <div className="alert alert-success">
            <h4>Agendamento Confirmado!</h4>
            <p>
              Seu agendamento foi realizado com sucesso. Guarde o protocolo
              abaixo para futuras consultas.
            </p>
            <p>
              <strong>Protocolo:</strong> {payload.protocolo}
            </p>
            <a
              href={`https://api.whatsapp.com/send?phone=${payload.celular}&text=${payload.mensagem}`}
              className="btn btn-success"
              target="_blank"
              rel="noopener noreferrer"
            >
              Enviar Agendamento no WhatsApp
            </a>
            <p>
              Para entrar em contato com nossa equipe, clique no botão abaixo
              para conversar via WhatsApp:
            </p>
            <a
              href="https://api.whatsapp.com/send?phone=5514998974587&text=Olá,%20não%20encontrei%20meu%20cadastro%20no%20sistema.%20Poderiam%20me%20ajudar?"
              className="btn btn-success btn-lg"
              target="_blank"
              rel="noopener noreferrer"
            >
              Falar com a Clínica no WhatsApp
            </a>
            <p className="mt-3">
              Ou, se preferir, ligue para nós pelo telefone:{' '}
              <strong>(14) 3402-5252</strong>.
            </p>
            <hr />
          </div>
        </div>
      )}

      {isMensagem && (
        <header className="text-center m-4">
          <div className="text-center m-4">
            <img width="600" height="110" src={header} alt="header" />
          </div>
          <div className="alert alert-warning mt-4">
            <h4 className="alert-heading">Ops! Paciente não encontrado.</h4>
            <p>
              Não encontramos nenhum cadastro associado ao CPF informado (
              <strong>{cpf}</strong>).{' '}
            </p>
            <p>
              Por favor, confira os dados digitados. Se precisar de ajuda,
              estamos à disposição para auxiliá-lo.
            </p>
            <hr />
            <p>
              Para entrar em contato com nossa equipe, clique no botão abaixo
              para conversar via WhatsApp:
            </p>
            <a
              href="https://api.whatsapp.com/send?phone=5514998974587&text=Olá,%20não%20encontrei%20meu%20cadastro%20no%20sistema.%20Poderiam%20me%20ajudar?"
              className="btn btn-success btn-lg"
              target="_blank"
              rel="noopener noreferrer"
            >
              Falar com a Clínica no WhatsApp
            </a>
            <p className="mt-3">
              Ou, se preferir, ligue para nós pelo telefone:{' '}
              <strong>(14) 3402-5252</strong>.
            </p>
            <hr />
            <p className="mb-0">
              Estamos aqui para garantir o melhor atendimento. Agradecemos pela
              sua compreensão!
            </p>
          </div>
        </header>
      )}

      {isAgendamento && (
        <>
          <div className="text-center m-4">
            <img width="600" height="110" src={header} alt="header" />
          </div>
          <h4 className="text-center">
            Olá <strong>{paciente.nome}</strong>, agende sua consulta de forma
            rápida e prática!
          </h4>
          <div className="row justify-content-center">
            <Alert variant="info">
              <Alert.Heading style={{ textAlign: 'center' }}>
                Selecione um Convênio
              </Alert.Heading>
              Lembre-se de selecionar um <strong>Médico</strong> antes de
              selecionar o <strong>Horário</strong>!
            </Alert>
          </div>
          <div className="row justify-content-center">
            {alert.show && (
              <Alert variant={alert.variant} style={{ textAlign: 'right' }}>
                {alert.mensagem}
              </Alert>
            )}
            {isError422 && (
              <a
                href="https://api.whatsapp.com/send?phone=5514998974587&text=Olá,%20poderiam%20me%20ajudar?"
                className="btn btn-success btn-lg"
                target="_blank"
                rel="noopener noreferrer"
              >
                Falar com a Clínica no WhatsApp
              </a>
            )}
          </div>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                  {getStepContent(
                    index,
                    classes,
                    selectedDate,
                    selectedIdxMed,
                    selectedIdxConv,
                    selectedIdxAgenda,
                    handleDateChange,
                    handleMedicoClick,
                    handleConvenioClick,
                    medicos,
                    paciente,
                    convenioAgendaPac,
                    handlePrevDay,
                    handleNextDay,
                    dateFormatted,
                    horariosAgenda,
                    handleHorarioClick,
                    formatarHora,
                    selectedHorario,
                    handleChangeDtAgenda,
                    dateAgenda,
                    datesAvailableIn30Days,
                    nomeMedico,
                    siglaConvenio,
                    horario,
                    handleConfirm,
                    agenda,
                    handleReset
                  )}
                  {activeStep < steps.length - 1 ? (
                    <>
                      <Button
                        size="sm"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                      >
                        Anterior
                      </Button>{' '}
                      <Button
                        size="sm"
                        onClick={
                          activeStep === steps.length - 2
                            ? handleNext
                            : handlePrev
                        }
                      >
                        {activeStep === steps.length - 2
                          ? 'Agendar'
                          : 'Próximo'}
                      </Button>
                    </>
                  ) : null}
                </StepContent>
              </Step>
            ))}
          </Stepper>

          {/*   {activeStep === steps.length && (
            <Modal show={show} onHide={() => setShow(false)} centered>
              <Modal.Header closeButton>
                <Modal.Title>Confirmação de Agendamento!</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ul>
                  <li>
                    {paciente.nome}, estamos <i>"finalizando"</i> o seu
                    Agendamento, por favor confira os dados abaixo e tecle em{' '}
                    <strong>"CONFIRMAR"</strong>!
                  </li>
                  <blockquote>
                    <q>
                      <cite>
                        Agendamento marcado com {nomeMedico} no Convênio{' '}
                        {siglaConvenio} para {dateFormatted} às{' '}
                        {formatarHora(horario)}. Chegar com 15 minutos de
                        antecedência.
                      </cite>
                    </q>
                  </blockquote>
                  <li>
                    LOCAL: Av. Vicente Ferreira 780 – ao lado do P.S. da Santa
                    Casa
                  </li>
                  <li>
                    Temos estacionamento próprio na Rua Amazonas, 777.
                    Atenciosamente!
                  </li>
                </ul>
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-start">
                <Button
                  variant="success"
                  size="sm"
                  onClick={e => handleConfirm(e, agenda)}
                >
                  <strong>Confirmar</strong>
                </Button>{' '}
                <Button variant="warning" size="sm" onClick={handleReset}>
                  <strong>Alterar</strong>
                </Button>
              </Modal.Footer>
            </Modal>
          )} */}
          <footer className="text-center mt-4">
            <p>
              &copy; Instituto do Coração de Marília - ICM. Todos os direitos
              reservados.
            </p>
          </footer>
        </>
      )}
    </Container>
  );
}
